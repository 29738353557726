import React, {useCallback, useEffect, useState} from 'react'
import {useSnackbar} from 'notistack'
import {useParams} from 'react-router'
import {Stack} from '@mui/material'
import {
  DataGridPro,
  GridCellEditStopParams,
  GridFilterModel,
  GridLinkOperator,
  useGridApiRef,
  gridFilteredSortedRowIdsSelector,
  GridEventListener
} from '@mui/x-data-grid-pro'
import DataGridTotalRows from 'src/components/shl/shared/DataGridTotalRows'
import config from 'src/config'
import {useAuth} from 'src/contexts/Auth'
import {
  examineeColumns,
  GridColumnExaminess,
  TestScreenEnum
} from 'src/components/shl/tests/testUtils'
import {getDateFromApi} from 'src/utils/formatTime'
import {EmployeeToExamination} from 'src/entities/EmployeeToExamination'
import {changeFilterData} from 'src/utils/externalFilter'
import useExaminationTypeList from 'src/hooks/useExaminationTypeList'
import TestExamineeModal from 'src/components/shl/tests/testExaminees/TestExamineeModal'
import TestExamineeToolbar from 'src/components/shl/tests/testExaminees/TestExamineeToolbar'
import useInitalState from 'src/hooks/useInitalState'
import Examination from 'src/entities/Examination'
import {ExamineesResultModal} from './ExamineesResultsModal'

interface TestExamineeListProps {
  isOpenAddExamineeDialog: boolean
  setIsOpenAddExamineeDialog: (value: boolean) => void
  examinationOrder?: number
}

export function TestExamineeList({
  isOpenAddExamineeDialog,
  setIsOpenAddExamineeDialog,
  examinationOrder
}: TestExamineeListProps) {
  const [openPanel, setOpenPanel] = useState(false)
  const [selectedRow, setSelectedRow] = useState<EmployeeToExamination>()
  const [examineesList, setExamineesList] = useState<EmployeeToExamination[]>(
    []
  )

  const [loading, setLoading] = useState<boolean>()
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
    linkOperator: GridLinkOperator.And
  })
  const [testTrakMissQNList, setTestTrakMissQNList] = useState<Examination[]>()
  const {fetchWithUser} = useAuth()
  const {enqueueSnackbar} = useSnackbar()
  const {id, trackId, statusId} = useParams()
  const apiRef = useGridApiRef()
  const {examinationTypeList, isExaminationTypeListLoading} =
    useExaminationTypeList()
  const {initialState} = useInitalState(
    TestScreenEnum.Examinees,
    apiRef.current?.exportState,
    apiRef.current.restoreState,
    apiRef.current.setColumnVisibilityModel
  )

  const getExamineesList = useCallback(() => {
    return fetchWithUser(
      `${config.apiUrl}/Examination/GetEmployeeToExaminationListByOrderId/${id}`
    )
      .then(res => res.json())
      .then((data: (EmployeeToExamination & {submissionTime?: string})[]) => {
        const rows: EmployeeToExamination[] = data.map(ec => ({
          ...ec,
          submissionTime: getDateFromApi(ec.submissionTime)
        }))
        setExamineesList(rows)
      })
      .catch(e => {
        enqueueSnackbar('Internal server error, Please try again later', {
          variant: 'error'
        })
        throw e
      })
  }, [id, fetchWithUser, enqueueSnackbar])

  const getTestTrakMissQNList = useCallback(() => {
    return fetchWithUser(
      `${config.apiUrl}/Examination/GetExaminationsMissQNByOrderId/${id}`
    )
      .then(res => res.json())
      .then((data: Examination[]) => {
        setTestTrakMissQNList(data)
      })
      .catch(e => {
        enqueueSnackbar('Internal server error, Please try again later', {
          variant: 'error'
        })
        throw e
      })
  }, [id, fetchWithUser, enqueueSnackbar])

  const getPageValues = useCallback(async () => {
    setLoading(true)
    await Promise.all([getExamineesList(), getTestTrakMissQNList()])
    setLoading(false)
  }, [getExamineesList, getTestTrakMissQNList])

  const initialFilterModel = useCallback(() => {
    if (!isExaminationTypeListLoading) {
      const trackName =
        examinationTypeList.find(e => e.trackID.toString() === trackId)
          ?.trackShortName || ''
      setFilterModel(prev => {
        const newFilterModel = {
          ...prev,
          items: statusId
            ? changeFilterData(
                prev.items,
                GridColumnExaminess.SubmissionStatus,
                +statusId,
                'is'
              )
            : prev.items
        }
        return {
          ...newFilterModel,
          items: changeFilterData(
            newFilterModel.items,
            GridColumnExaminess.Track,
            trackName,
            'contains'
          )
        }
      })
    }
  }, [examinationTypeList, trackId, statusId, isExaminationTypeListLoading])

  useEffect(() => {
    getPageValues()
  }, [getPageValues])

  useEffect(() => {
    setSelectedRow(examineesList[0])
  }, [examineesList])

  useEffect(initialFilterModel, [initialFilterModel])

  const updateScore = (params: GridCellEditStopParams, event: any) => {
    fetchWithUser(
      `${config.apiUrl}/Examination/UpdateEmployeeToExamination/${params.row.employeeToExaminationID}`,
      {
        method: 'PUT',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          ...params.row,
          score: +event.target.value,
          examination: null
        })
      }
    ).catch(e => {
      enqueueSnackbar('Internal server error, Please try again later', {
        variant: 'error'
      })
      throw e
    })
  }

  const onKeyDown: GridEventListener<'cellKeyDown'> = (params, event) => {
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      event.preventDefault()
      const visibleRows = Array.from(
        apiRef.current.getVisibleRowModels().values()
      )
      const visibleRowIds = visibleRows.map(row => row.employeeToExaminationID)
      const currentIndex = visibleRowIds.indexOf(params.id as string)
      if (currentIndex !== -1) {
        const nextIndex =
          event.key === 'ArrowDown' ? currentIndex + 1 : currentIndex - 1
        if (nextIndex >= 0 && nextIndex < visibleRowIds.length) {
          apiRef.current.setSelectionModel([visibleRowIds[nextIndex]])
          setSelectedRow(visibleRows[nextIndex] as EmployeeToExamination)
          console.log(visibleRows[nextIndex])
        }
      }
    }
  }

  return (
    <>
      <Stack spacing={3} height="calc(100% - 84px)">
        <DataGridPro
          apiRef={apiRef}
          rows={examineesList}
          columns={examineeColumns(setOpenPanel)}
          experimentalFeatures={{newEditingApi: true}}
          autoPageSize
          headerHeight={47}
          rowHeight={46}
          density="compact"
          getRowId={row => row.employeeToExaminationID}
          loading={loading || !initialState}
          filterModel={filterModel}
          onFilterModelChange={newValue => setFilterModel(newValue)}
          onCellKeyDown={onKeyDown}
          onRowClick={params => setSelectedRow(params.row)}
          onCellEditStop={updateScore}
          components={{
            Toolbar: TestExamineeToolbar,
            Footer: () => (
              <DataGridTotalRows
                amount={gridFilteredSortedRowIdsSelector(apiRef).length}
              />
            )
          }}
          componentsProps={{
            toolbar: {
              trackFilterToolbarProps: {
                trackList: examinationTypeList,
                selectedTrackName:
                  filterModel.items.find(x => x.columnField === 'track')
                    ?.value || 0,
                setFilterModel: setFilterModel,
                urlForChangeModel: `/shl/tests/${id}/examinees`,
                selectedStatus:
                  filterModel.items.find(
                    x => x.columnField === GridColumnExaminess.SubmissionStatus
                  )?.value || 0,
                placeholderSearch: 'Search Examinee'
              },
              cuiToolbarProps: {
                screenId: TestScreenEnum.Examinees,
                exportState: apiRef.current.exportState,
                columnVisibilityModel:
                  apiRef.current.state?.columns.columnVisibilityModel,
                csvFileName: `testNumber${examinationOrder ?? ''}Examinees`
              }
            }
          }}
          sx={{
            '.css-yrdy0g-MuiDataGrid-columnHeaderRow': {
              background: '#F4F6F8',
              borderRadius: 1
            },
            '.MuiDataGrid-withBorder': {
              borderRight: 'none'
            },
            '.MuiDataGrid-footerContainer': {
              minHeight: 36
            }
          }}
        />
      </Stack>
      <TestExamineeModal
        onCancelClick={() => setIsOpenAddExamineeDialog(false)}
        isOpen={isOpenAddExamineeDialog}
        testId={+id!}
        testOrderNumber={examinationOrder}
        setExamineeList={apiRef.current.updateRows}
        getExamineeById={(empId: number) => apiRef.current.getRow(empId)}
        getExamineesList={getExamineesList}
        testTrakMissQNList={testTrakMissQNList}
      />
      <ExamineesResultModal
        openPanel={openPanel}
        setOpenPanel={setOpenPanel}
        selectedEmployee={selectedRow}
      />
    </>
  )
}
