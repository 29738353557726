import {useEffect, useRef, useState} from 'react'
import {Resizable, ResizableProps} from 're-resizable'
import {Box, styled} from '@mui/material'
interface CuiResizableProps extends ResizableProps {
  showColor?: boolean
}
const StyledResizable = styled((props: CuiResizableProps) => (
  <Resizable as={props.as} {...props}>
    {props.children}
  </Resizable>
))(({showColor}) => ({
  position: 'relative',
  [`& .resizableHandler`]: {
    right: '0 !important',
    bottom: '0 !important',
    ...(showColor === true && {
      backgroundColor: 'rgba(128, 128, 128, 0.5)', // Grey color with opacity
      width: '5px !important'
    })
  }
}))
export default function CuiResizable({
  children,
  onResize,
  onResizeStart,
  as,
  showColor = false,
  ...others
}: CuiResizableProps) {
  const [parentResizeableHeight, setParentResizableHeight] = useState(0)
  const ref = useRef<any>(null)
  const parentScrollOffsetX = useRef(0)
  const parentScrollOffsetY = useRef(0)
  useEffect(() => {
    if (!ref.current) return
    const resizeObserver = new ResizeObserver(() => {
      setParentResizableHeight(ref.current.scrollHeight || 0)
    })
    resizeObserver.observe(ref.current)
    return () => resizeObserver.disconnect()
  }, [])
  return (
    <StyledResizable
      className="resizable"
      onResizeStart={(e, direction, ref) => {
        parentScrollOffsetX.current = ref.scrollLeft || 0
        parentScrollOffsetY.current = ref.scrollTop || 0
        onResizeStart?.(e, direction, ref)
      }}
      onResize={(e, direction, ref, delta) => {
        ref.scrollTo(parentScrollOffsetX.current, parentScrollOffsetY.current)
        onResize?.(e, direction, ref, delta)
      }}
      handleClasses={{
        right: 'resizableHandler',
        bottom: 'resizableHandler',
        bottomRight: 'resizableHandler'
      }}
      handleComponent={{
        right: <Box sx={{height: parentResizeableHeight + 'px !important'}} />,
        left: <Box sx={{height: parentResizeableHeight + 'px !important'}} />
      }}
      showColor={showColor}
      {...others}
      style={{
        width: '100%', // Set width to 100%
        height: '100%', // Ensure it takes full height
        overflow: 'hidden', // Hide overflow on the resizable container
        position: 'relative',
        ...others.style
      }}
      as={as as any}
    >
      {children}
    </StyledResizable>
  )
}
