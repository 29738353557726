import {CSSProperties, Dispatch, useCallback, useEffect, useState} from 'react'
import {alpha, Box, IconButton, Stack, Typography} from '@mui/material'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'
import {useSnackbar} from 'notistack'
import {useParams} from 'react-router'
import {UserTest} from '@madisoncres/cb-form-creator'
import CuiResizable from 'src/components/shl/shared/CuiResizable'
import Track from 'src/entities/Track'
import {
  EmployeeToExamination,
  SubmissionStatusEnum
} from 'src/entities/EmployeeToExamination'
import Form from 'src/entities/Form'
import {useAuth} from 'src/contexts/Auth'
import config from 'src/config'

interface ExamineesResultModalProps {
  openPanel: boolean
  setOpenPanel: Dispatch<React.SetStateAction<boolean>>
  selectedEmployee?: EmployeeToExamination
}

export function ExamineesResultModal({
  openPanel,
  setOpenPanel,
  selectedEmployee
}: ExamineesResultModalProps) {
  const [formsList, setFormsList] = useState<Form[]>()
  const {fetchWithUser} = useAuth()
  const {enqueueSnackbar} = useSnackbar()
  const {id} = useParams()
  const floatingStyle: CSSProperties = {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 999
  }

  const getFormList = useCallback(async () => {
    await fetchWithUser(
      `${config.apiUrl}/Form/GetFormListByExaminationOrderID/${id}`
    )
      .then(res => res.json())
      .then((data: Form[]) => {
        setFormsList(data)
      })
      .catch(e => {
        enqueueSnackbar('Internal server error, Please try again later', {
          variant: 'error'
        })
        throw e
      })
  }, [id, fetchWithUser, enqueueSnackbar])

  useEffect(() => {
    getFormList()
  }, [getFormList])

  return (
    <CuiResizable
      minWidth="20%"
      maxWidth="80%"
      maxHeight="100%"
      minHeight="100%"
      defaultSize={{height: '100%', width: '40%'}}
      enable={{left: true}}
      style={{
        height: '100%',
        display: openPanel ? 'flex' : 'none',
        ...floatingStyle
      }}
    >
      <Stack
        boxShadow={10}
        bgcolor="white"
        ml={1}
        height="100%"
        width="100%"
        overflow="auto"
      >
        <Box mt={6} position="fixed" zIndex={999}>
          <IconButton onClick={() => setOpenPanel(false)}>
            <ClearRoundedIcon />
          </IconButton>
        </Box>
        {selectedEmployee?.submissionStatus === SubmissionStatusEnum.Lack ? (
          <Stack alignItems="center" width="100%" mt={20}>
            <Typography
              width="fit-content"
              variant="subtitle1"
              borderRadius={3}
              textAlign="center"
              p={3}
              border={2}
              borderColor={alpha(
                selectedEmployee?.examination?.baseExamination.track
                  ?.primeColor ?? '#ffff',
                0.2
              )}
            >
              {`${selectedEmployee.employee?.firstName} ${selectedEmployee.employee?.lastName} `}
              still hasn't answered this test
            </Typography>
          </Stack>
        ) : (
          <>
            <UserTest
              testContent={
                formsList?.find(
                  e => e.formID === selectedEmployee?.formResult?.formID
                )?.content ?? ''
              }
              track={
                selectedEmployee?.examination?.baseExamination.track ??
                ({} as Track)
              }
              testResult={selectedEmployee?.formResult?.content ?? ''}
            />
            <Stack
              justifyContent="center"
              direction="row"
              bottom={0}
              position="fixed"
              zIndex={999}
            >
              <Typography
                variant="subtitle1"
                p={1}
                bgcolor={alpha(
                  selectedEmployee?.examination?.baseExamination.track
                    ?.primeColor ?? '#ffff',
                  0.5
                )}
              >
                {`${selectedEmployee?.employee?.firstName} ${selectedEmployee?.employee?.lastName}'s test`}
              </Typography>
            </Stack>
          </>
        )}
      </Stack>
    </CuiResizable>
  )
}
